import React from 'react';
import { Link } from 'gatsby';

import { aanbodData } from '../data/aanbodData.js';

import toastStyles from '../css/toast.module.css';
import '../css/global.css';

const ToastMessage = props => {
	const aanbodItem = aanbodData.find(item => item.id === 9)

	return (
		<div
			className={`${toastStyles.container} ${props.show &&
				toastStyles.show}`}
		>
			<Link
				to={`/aanbod/#${aanbodItem.title}`}
				state={{ data: aanbodItem }}
				className={toastStyles.link}
			>
				Blind leren typen kan vanaf nu ook bij Kine+ Kids!
			</Link>

			<button
				type="button"
				className={toastStyles.close}
				onClick={props.handleClose}
			>
				<svg
					version="1.1"
					fill="#00aeaf"
					width="18"
					height="18"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 475.2 475.2"
				>
					<path
						d="M405.6,69.6C360.7,24.7,301.1,0,237.6,0s-123.1,24.7-168,69.6S0,174.1,0,237.6s24.7,123.1,69.6,168s104.5,69.6,168,69.6
					s123.1-24.7,168-69.6s69.6-104.5,69.6-168S450.5,114.5,405.6,69.6z M386.5,386.5c-39.8,39.8-92.7,61.7-148.9,61.7
					s-109.1-21.9-148.9-61.7c-82.1-82.1-82.1-215.7,0-297.8C128.5,48.9,181.4,27,237.6,27s109.1,21.9,148.9,61.7
					C468.6,170.8,468.6,304.4,386.5,386.5z"
					/>
					<path
						d="M342.3,132.9c-5.3-5.3-13.8-5.3-19.1,0l-85.6,85.6L152,132.9c-5.3-5.3-13.8-5.3-19.1,0c-5.3,5.3-5.3,13.8,0,19.1
					l85.6,85.6l-85.6,85.6c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4l85.6-85.6l85.6,85.6c2.6,2.6,6.1,4,9.5,4
					c3.5,0,6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1l-85.4-85.6l85.6-85.6C347.6,146.7,347.6,138.2,342.3,132.9z"
					/>
				</svg>
			</button>
		</div>
	)
}

export default ToastMessage
