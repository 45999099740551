import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/SEO'
import Toast from '../components/Toast'

import indexStyles from '../css/index.module.css'
import logo from '../images/logo.png'
import aanbod from '../images/aanbod-home.svg'
import elke from '../images/elke.png'

const IndexPage = () => {
	const [toast, setToast] = useState(false)

	useEffect(() => {
		const timer = setTimeout(() => {
			setToast(true)
		}, 1500)

		return () => {
			clearTimeout(timer)
		}
	}, [])

	return (
		<Layout>
			<SEO title="Welkom" />
			<div className={indexStyles.header}>
				<h1>
					Kinepraktijk Kine<span className={indexStyles.plus}>+</span>{' '}
					Kids Oostrozebeke
				</h1>
				<Link to="/contact/" className={indexStyles.link}>
					CONTACTEER ONS
				</Link>
			</div>
			<div className={indexStyles.container}>
				<h2 className="hidden">Kine+ Kids Oostrozebeke</h2>
				<img
					src={logo}
					alt="Kine+ Kids logo"
					title="Kine+ Kids logo"
					className={indexStyles.image}
					width="425"
					height="223"
				></img>
				<p className={indexStyles.peetje}>
					Kinderen groeien en leren gaandeweg ontdekken. Ze
					ontwikkelen op verschillende vlakken en aan hun eigen tempo.
					Bij sommige kinderen verloopt de{' '}
					<span className="fat">motorische ontwikkeling</span> echter
					vertraagd ten opzichte van leeftijdsgenoten.{' '}
					<span className="fat">Kine+ Kids</span> probeert op dat
					moment een meerwaarde te bieden om de verdere ontwikkeling
					te stimuleren.
				</p>
				<div>
					<h2 className={indexStyles.subtitle}>AANBOD</h2>
					<p className={indexStyles.peetje}>
						Kinderen kunnen terecht in de praktijk voor kinderkine voor onder meer{' '}
						<span className="fat">
							ademhalingstherapie, ontwikkelingsstimulatie,
							psychomotorische therapie…{' '}
						</span>{' '}
						Tijdens een eerste gesprek en motorische inspectie wordt
						de verdere behandeling besproken. Regelmatig worden de
						behandeldoelen geëvalueerd en kan therapie worden
						aangepast afhankelijk van het bekomen resultaat.
						<br />
						<br />
						In samenspraak kan de behandeling worden uitgevoerd in de praktijk, een crèche, in school of aan huis in de gemeentes Desselgem, Ooigem, Izegem, Ingelmunster, Wielsbeke, Waregem, Zulte, Dentergem, Tielt, Wakken, Meulebeke, Marialoop, Sint-Eloois-Vijve, Sint-Baafs-Vijve, Harelbeke, Beveren-Leie, Deerlijk, Lendelede,..
					</p>
					<Link to="/aanbod" className={indexStyles.link}>
						ONTDEK HET AANBOD
					</Link>
				</div>
				<img
					src={aanbod}
					className={indexStyles.image}
					alt="Aanbod cards"
					title="Aanbod cards"
					width="505"
					height="255"
				></img>
				<p
					className={[
						indexStyles.peetje,
						indexStyles.specialPeetje,
					].join(' ')}
				>
					Om een positief resultaat te bevorderen staat binnen de
					behandeling van uw kind ook{' '}
					<span className="fat">ouderbegeleiding</span> en de
					overdracht van informatie aan de omgeving centraal. Het is
					namelijk belangrijk dat de tips en tricks die in de praktijk
					worden toegepast ook in de thuisomgeving worden gebruikt.
				</p>
				<div>
					<h2 className={indexStyles.about}>OVER ONS</h2>
					<p className={indexStyles.peetje}>
						De behandeling is individueel en persoonlijk gericht.
						Daarom stel ik ook mezelf graag even persoonlijk voor.
						In 2017 behaalde ik mijn diploma
						Revalidatiewetenschappen en Kinesitherapie,
						Afstudeerrichting pediatrie, aan de Universiteit van
						Gent. Nu werk ik in een pediatrische groepspraktijk in
						Kruishoutem en sinds januari 2019 kunnen kinderen ook
						terecht in mijn eigen{' '}
						<span className="fat">
							praktijk Kine+ Kids in Oostrozebeke.
						</span>
					</p>
					<p className={indexStyles.name}>Elke De Wispelaere</p>
				</div>
				<img
					src={elke}
					alt="Elke De Wispelaere"
					title="Elke De Wispelaere"
					width="250"
					height="250"
				></img>
			</div>

			<Toast show={toast} handleClose={() => setToast(false)}/>
		</Layout>
	)
}

export default IndexPage
